import './global/accordion';
import './global/animation';
import './global/share-link';
import './global/site-header';
import './global/site-menu';
import './global/sliding-line';
import './global/video';

import bootComponents from './lib/components';

// Boot components
bootComponents('global');

function decode(s) {
    return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
}

export default class Cookies {

    static set(key, value, attributes) {
        attributes = {path: '/', ...attributes};

        if (typeof attributes.expires === 'number') {
            attributes.expires = new Date(new Date() * 1 + attributes.expires * 864e+5);
        }

        attributes.expires = attributes.expires ? attributes.expires.toUTCString() : '';

        key = encodeURIComponent(String(key))
            .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
            .replace(/[()]/g, escape);

        value = encodeURIComponent(String(value))
            .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

        let stringifiedAttributes = '';
        for (const attrKey of Object.keys(attributes)) {
            const attrValue = attributes[attrKey];

            stringifiedAttributes += '; ' + attrKey;
            if (attrValue === true) {
                continue;
            }

            stringifiedAttributes += '=' + attrValue.split(';')[0];
        }

        document.cookie = `${key}=${value}${stringifiedAttributes}`;
    }

    static get(key = null) {
        const jar = {};
        const cookies = document.cookie ? document.cookie.split('; ') : [];

        for (const cookie of cookies) {
            const parts = cookie.split('=');

            try {
                const name = decode(parts[0]);
                const value = decode(parts.slice(1).join('='));

                if (key === name) {
                    return value;
                } else {
                    jar[name] = value;
                }
            } catch (e) {}
        }

        return key ? jar[key] : undefined;
    }

    static remove(key) {
        Cookies.set(key, '', {expires: -1})
    }

}

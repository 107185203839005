import Component from '../lib/component';

export default class Animation extends Component {

    initialize() {
        this.animations = this.el.find('[data-animation]');

        this.intersectionObserver = null;

        this.onResize = this.onResize.bind(this);
        this.onIntersect = this.onIntersect.bind(this);
    }

    bindEvents() {
        this.window.on('resize', this.onResize).trigger('resize');
    }

    onResize() {
        this.intersectionObserver?.disconnect();
        this.createIntersectionObserver();
    }

    onIntersect(entries) {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                const el = $(entry.target);
                const textEl = el.find('[data-animate-text]');
                const showText = !el.hasClass('animate') && textEl.length && textEl.data('animate-text').length;

                el.addClass('animate');

                if (showText) {
                    this.animateText(textEl);
                }
            }
        }
    }

    animateText(el) {
        const text = el.data('animate-text');
        const chars = text.split('').map(c => c === '$' ? '<br>' : c);
        const writeText = (len) => {
            const fragment = chars.slice(0, len).join('');
            el.html(fragment);
            if (len !== chars.length) {
                setTimeout(() => writeText(len + 1), 125);
            }
        };
        setTimeout(() => writeText(0), 500);
    }

    createIntersectionObserver() {
        const observableFromYEdge = Math.min(this.window.height() / 4, 200);

        const observer = new IntersectionObserver(this.onIntersect, {
            threshold: 0.5,
            rootMargin: `-${observableFromYEdge}px 0px`,
        });

        this.animations.each((i, el) => {
            observer.observe(el);
        });
    }

}


import BREAKPOINTS from '../lib/breakpoints';
import Component from '../lib/component';

export default class SlidingLine extends Component {

    initialize() {
        this.active = false;

        this.menuItems = this.el.find('.site-menu-item');
        this.activeMenuItem = this.el.find('.site-menu-item.is-active');
        this.buildMenuItemData();

        this.underline = $('<div class="site-menu-underline">');
        this.underlineOrigin = {left: 0, width: 0};
        this.underlinePosition = {left: 0, width: 0};
        this.buildUnderlineData();

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    bindEvents() {
        this.window.on('resize', () => {
            if (!this.active && BREAKPOINTS.up('lg')) {
                this.active = true;
                this.attachEvents();
            }
            if (this.active && BREAKPOINTS.down('md')) {
                this.active = false;
                this.detachEvents();
            }
        });

        this.window.trigger('resize');

        this.resizeObserver = new ResizeObserver(() => {
            this.buildMenuItemData();
            this.buildUnderlineData();
            this.updateLinePosition();
        });
        this.resizeObserver.observe(this.el[0]);
    }

    attachEvents() {
        this.menuItems.on('mouseenter', this.onMouseEnter);
        this.menuItems.on('mouseleave', this.onMouseLeave);

        this.underline.appendTo(this.el);
        this.updateLinePosition();
    }

    detachEvents() {
        this.underline.detach();
        this.menuItems.off('mouseenter', this.onMouseEnter);
        this.menuItems.off('mouseleave', this.onMouseLeave);
    }

    buildMenuItemData() {
        this.menuItems.each((i, el) => {
            const item = $(el);
            const link = item.find('.site-menu-link');
            item.data('sliding-line', {
                left: link.position().left,
                width: link.width(),
            });
        });
    }

    buildUnderlineData() {
        const origin = this.activeMenuItem.length
            ? this.activeMenuItem.data('sliding-line')
            : {left: 0, width: 0};

        this.underlineOrigin = origin;
        this.underlinePosition = origin;
    }

    onMouseEnter(e) {
        const target = $(e.target).closest('.site-menu-item');
        if (target.length) {
            this.underlinePosition = target.data('sliding-line');
            this.updateLinePosition();
        }
    }

    onMouseLeave() {
        this.underlinePosition = this.underlineOrigin;
        this.updateLinePosition();
    }

    updateLinePosition() {
        this.underline.css({
            left: `${this.underlinePosition.left}px`,
            width: `${this.underlinePosition.width}px`,
        });
    }
}

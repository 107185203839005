const idCounter = {};
const loadedJSON = [];
const loadedScripts = {};

export const TRANSITION_END = 'transitionend';

export function getJSON(url) {
    const deferred = $.Deferred();

    if (loadedJSON.hasOwnProperty(url)) {
        deferred.resolve(loadedJSON[url]);
    } else {
        $.getJSON(url)
            .done((data) => {
                loadedJSON[url] = data;
                deferred.resolve(data);
            })
            .fail(() => deferred.reject());
    }

    return deferred.promise();
}

export function loadScript(url) {
    if (!loadedScripts.hasOwnProperty(url)) {
        const deferred = $.Deferred();
        loadedScripts[url] = deferred.promise();

        $.ajax(url, {
            cache: true,
            success: () => deferred.resolve(),
        });
    }

    return loadedScripts[url];
}

export function loadScriptWithCallback(url, param = 'cb') {
    if (!loadedScripts.hasOwnProperty(url)) {
        const deferred = $.Deferred();
        loadedScripts[url] = deferred.promise();

        const fn = uniqueId('_scriptCallback');
        window[fn] = () => {
            deferred.resolve();
        };

        jQuery.getScript(`${url}?${param}=${fn}`);
    }

    return loadedScripts[url];
}

export function emulateTransitionEnd(element, duration) {
    let called = false;
    const durationPadding = 5;
    const emulatedDuration = duration + durationPadding;

    function listener() {
        called = true;
        element.off(TRANSITION_END, listener);
    }

    element.on(TRANSITION_END, listener);
    setTimeout(() => called || element.trigger(TRANSITION_END), emulatedDuration);
}

export function uniqueId(prefix) {
    if (!idCounter.hasOwnProperty(prefix)) {
        idCounter[prefix] = 0;
    }

    const counter = idCounter[prefix]++;
    const entropy = toInt(Math.random() * 100000);

    return prefix + counter + entropy;
}

export function toInt(number) {
    return ~~number;
}

export function clamp(number, min = -Infinity, max = Infinity) {
    return Math.min(max, Math.max(min, number));
}

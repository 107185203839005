/**
 * Automatically instantiates components based on data-attributes
 * specifying component file-names.
 *
 * html
 *     <button data-component="disappear">disappear!</button>
 *
 * js
 *     // components/disappear.js
 *     import Component from '../lib/component';
 *     export default class Disappear extends Component {
 *
 *     }
 */

const html = $('html');
export default function bootComponents(pkg = 'global', root = 'html') {
    const instances = [];
    const components = $(root).find('[data-component]').addBack('[data-component]');

    components.each((i, el) => {
        const componentEl = $(el);
        componentEl.data('component').split(/\s*,\s*/).forEach((componentName) => {
            const componentConfig = componentEl.data(componentName);

            if (componentEl.data(`${componentName}-booted`)) {
                console.log(`Component ${componentName} already booted for:`, el);
                return;
            }

            try {
                const Component = require(`./../${pkg}/${componentName}.js`).default;
                instances.push(new Component(el, {...componentConfig}));
                componentEl.data(`${componentName}-booted`, true)
            } catch (e) {
                // console.error(e);
            }
        });
    });

    instances.forEach((instance) => instance.initialize());
    html.addClass('components-initialized');

    instances.forEach((instance) => instance.bindEvents());
    html.addClass('components-bound');
}

window.bootComponents = bootComponents;

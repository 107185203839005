import Component from '../lib/component';

export default class ShareLink extends Component{

    bindEvents() {
        this.el.on('click', (e) => {
            e.preventDefault();
            this.openWindow()
        })
    }

    openWindow() {
        const width = 500;
        const height = 300;
        const top = (this.window.height() - height) / 2;
        const left = (this.window.width() - width) / 2;

        const title = $('title').first().text() || 'Zipscreen';
        const currentUrl = window.location.origin + window.location.pathname;

        const url = this.el.attr('href')
            .replace(/{current_url}/g, currentUrl)
            .replace(/{title}/g, title);
        const name = 'share_link';
        const features = `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`;

        window.open(url, name, features);
    }

}

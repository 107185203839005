const myWindow = $(window);
const myDocument = $(document);

export default class Component {

    constructor(el, config) {
        this.el = $(el);
        this.config = {...this.defaultConfig, ...config};

        this.window = myWindow;
        this.document = myDocument;
    }

    get defaultConfig() {
        return {};
    }

    initialize() {}

    bindEvents() {}

}

import Component from '../lib/component';

export default class SiteHeader extends Component {

    initialize() {
        this.header = this.el.find('.site-header');
        this.darkRegions = this.el.find('[data-site-header="dark"]');

        this.isCollapsed = false;
        this.intersectionObserver = null;

        this.onScroll = this.onScroll.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onIntersect = this.onIntersect.bind(this);
    }

    bindEvents() {
        this.window.on('scroll', this.onScroll).trigger('scroll');
        this.window.on('resize', this.onResize).trigger('resize');
    }

    onScroll() {
        const position = this.window.scrollTop();

        if (position <= 0 && this.isCollapsed) {
            this.isCollapsed = false;
            this.el.removeClass('header-collapse');
            setTimeout(this.onResize, 200);
        }

        if (position > 0 && !this.isCollapsed) {
            this.isCollapsed = true;
            this.el.addClass('header-collapse');
            setTimeout(this.onResize, 200);
        }
    }

    onResize() {
        this.intersectionObserver?.disconnect();
        this.createIntersectionObserver();
    }

    onIntersect(entries) {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                this.el.addClass('header-dark');
                return;
            }
        }

        this.el.removeClass('header-dark');
    }

    createIntersectionObserver() {
        const headerHeight = this.header.outerHeight();
        const observableFromTop = headerHeight * 3 / 8;
        const observableFromBottom = this.window.height() - 5 / 8 * headerHeight;

        const observer = new IntersectionObserver(this.onIntersect, {
            rootMargin: `-${observableFromTop}px 0px -${observableFromBottom}px`,
        });

        this.darkRegions.each((i, el) => observer.observe(el));
    }

}


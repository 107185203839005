import Component from '../lib/component';
import KEY_CODES from '../lib/key-codes';

export default class Video extends Component {

    get defaultConfig() {
        return {
            embed_selector: 'script',
        };
    }

    initialize() {
        this.failed = false;
        this.embedScript = this.el.find(this.config.embed_selector);

        if (this.embedScript.length) {
            this.embedCode = this.embedScript.get(0).innerHTML;
        } else {
            this.failed = true;
            this.el.addClass('broken');
        }
    }

    bindEvents() {
        if (!this.failed) {
            this.el.one('click', () => this.activatePlayer());
            this.el.on('keydown', (e) => {
                if (e.which === KEY_CODES.ENTER) {
                    this.activatePlayer();
                }
            });
        }
    }

    activatePlayer() {
        this.el.addClass('active');
        this.el.append($(this.embedCode));
    }

}

const $window = $(window);
const widths = {
    xs: 0,
    sm: 393,
    md: 768,
    lg: 1200,
    xl: 1980,
};

const BREAKPOINTS = {
    ...widths,

    next(bp) {
        const names = Object.keys(widths);
        const index = names.indexOf(bp);
        return (index + 1 < names.length) ? names[index + 1] : null;
    },

    min(bp) {
        const min = widths[bp];
        return (min) ? min : null;
    },

    max(bp) {
        const next = this.next(bp);
        return (next) ? this.min(next) - 0.2 : null;
    },

    up(bp) {
        const min = this.min(bp);
        return (min) ? $window.width() >= min : true;
    },

    down(bp) {
        const max = this.max(bp);
        return (max) ? $window.width() < max : true;
    },

    only(bp) {
        return this.between(bp, bp);
    },

    between(lower, upper) {
        const min = this.min(lower);
        const max = this.max(upper);

        if (min !== null && max !== null) {
            return this.up(lower) && this.down(upper);
        } else if (max === null) {
            return this.up(lower);
        } else if (min === null) {
            return this.down(upper);
        }
    },
};

export default BREAKPOINTS;

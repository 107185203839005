import Component from '../lib/component';

export default class SiteMenu extends Component {

    initialize() {
        this.toggle = this.el.find('.site-header-menu-toggle');
    }

    bindEvents() {
        this.toggle.click(() => this.toggleMenu())
    }

    toggleMenu() {
        this.el.toggleClass('menu-active');
    }

}


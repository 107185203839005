import Component from '../lib/component';
import KEY_CODES from '../lib/key-codes';
import {uniqueId} from '../lib/util';

export default class Accordion extends Component {

    initialize() {
        this.panels = this.el.find('.accordion-panel');
        $(() => this.panels.each((i, el) => this.initializePanel($(el))));
    }

    bindEvents() {
        this.el.on('click', '.accordion-panel-header', (e) => {
            const target = this.getToggleTarget(e);
            this.togglePanel(target);
        });

        this.el.find('.accordion-panel-header').on('keydown', (e) => {
            const target = this.getToggleTarget(e);
            switch (e.which) {
                case KEY_CODES.ENTER:
                    return this.togglePanel(target);
                case KEY_CODES.LEFT_ARROW:
                    return this.hidePanel(target);
                case KEY_CODES.RIGHT_ARROW:
                    return this.showPanel(target);
            }
        });
    }

    collapseAllPanels() {
        this.el.find('.accordion-panel.active').each((i, el) => this.hidePanel($(el)));
    }

    showPanel(panel) {
        const header = panel.find('.accordion-panel-header');
        const content = panel.find('.accordion-panel-body');

        this.collapseAllPanels();

        panel.addClass('active');
        header.attr('aria-expanded', 'true');
        content.attr('aria-hidden', 'false');

        this.setContentHeight(content, 0);
        setTimeout(() => this.setContentHeightAnimated(content), 1);
    }

    hidePanel(panel) {
        const header = panel.find('.accordion-panel-header');
        const content = panel.find('.accordion-panel-body');

        panel.removeClass('active');
        header.attr('aria-expanded', 'false');
        content.attr('aria-hidden', 'true');

        this.setContentHeight(content);
        setTimeout(() => this.setContentHeightAnimated(content, 0), 1);
    }

    togglePanel(panel) {
        if (panel.hasClass('active')) {
            this.hidePanel(panel);
        } else {
            this.showPanel(panel);
        }
    }

    initializePanel(panel) {
        const headerId = uniqueId('accordion-header-');
        const contentId = uniqueId('accordion-body-');
        const header = panel.find('.accordion-panel-header');
        const content = panel.find('.accordion-panel-body');
        const isActive = panel.hasClass('active');

        header.attr('id', headerId);
        header.attr('tabindex', '0');
        header.attr('role', 'tab');
        header.attr('aria-controls', contentId);
        header.attr('aria-expanded', isActive ? 'true' : 'false');

        content.attr('id', contentId);
        content.attr('aria-labelledby', headerId);
        content.attr('role', 'tabpanel');
        header.attr('aria-hidden', isActive ? 'false' : 'true');
    }

    getToggleTarget({target}) {
        return $(target).closest('.accordion-panel');
    }

    setContentHeightAnimated(el, height = this.getContentHeight(el)) {
        if (height) {
            el.one('transitionend', () => el.height(''));
        }
        el.height(height);
    }

    setContentHeight(el, height = this.getContentHeight(el)) {
        el.css('transition', 'none');
        el.height(height);
        setTimeout(() => el.css('transition', ''), 1);
    }

    getContentHeight(el) {
        return el.find('.accordion-panel-body-inner').first().outerHeight() + 'px';
    }

}

